const project_data = [
  {
    title: { text: "Personal website", color: "green" },
    date: "December 2020 - Now",
    tags: ["Javscript", "React", "HTML", "CSS"],
    description:
      "I worked on this project to teach myself how to build a simple responsive porfolio website using Reactjs, HTML and CSS. It took me sometimes to wrap my head around CSS concepts, especially flex and grid styling. This is my first time writing a website on my own and I am quite happy with the result.",
    link: {
      url: "https://www.kaungsithu.com/",
      text: "Learn more about the project",
    },
  },
  {
    title: { text: "Maze Game", color: "Blue" },
    date: "November 2021",
    tags: ["Java", "School Project"],
    description:
      "A group project for an introductory course for Software Engineering. I mostly did the work on the interface of the game and testing. I also built some of the components for the game. The project gave me many hands-on experience with software development process.",
    link: {
      url: "https://github.com/Kaung722/MazeGame",
      text: "Learn more about the project",
    },
  },
];

export default project_data;
