import "./App.css";

import Nav from "./Nav";

import Footer from "./Footer";
import AnimatedRoutes from "./AnimatedRoutes";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <Nav />
        <div className="ContentBox">
          <AnimatedRoutes />
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
