import React from "react";
import "./home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faDiagramProject } from "@fortawesome/free-solid-svg-icons";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function Home() {
  const linkStyle = {
    color: "white",
    textDecoration: "none",
  };
  return (
    <motion.div
      className="home"
      inital={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <div className="hello">
        <h1 className="home-title">Hello, World!</h1>
      </div>
      <div className="tags1">
        <p>I'm a</p>
        <div className="moving-tags">
          <span>Computing Science Student</span>
          <span>Beginner Website Developer</span>
          <span>Newbie UI/UX Designer</span>
          <span>Programmer</span>
        </div>
      </div>
      <div className="tags2">
        <p>I like</p>
        <div className="moving-tags">
          <span>Movies</span>
          <span>Comics</span>
          <span>Music</span>
          <span>Martial Arts</span>
        </div>
      </div>
      <ul className="home-icon-list">
        <li>
          <Link style={linkStyle} to="/about">
            <FontAwesomeIcon icon={faUser} className="home-icon" />
          </Link>
        </li>
        <li>
          <Link style={linkStyle} to="/projects">
            <FontAwesomeIcon icon={faDiagramProject} className="home-icon" />
          </Link>
        </li>
        <li>
          <Link style={linkStyle} to="/career">
            <FontAwesomeIcon icon={faBriefcase} className="home-icon" />
          </Link>
        </li>
        <li>
          <Link style={linkStyle} to="/contact">
            <FontAwesomeIcon icon={faAddressCard} className="home-icon" />
          </Link>
        </li>
      </ul>
    </motion.div>
  );
}

export default Home;
