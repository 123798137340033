import React from "react";
import Project from "./Project";
import project_data from "./ProjectData";
import "./ProjectList.css";

function ProjectList() {
  return (
    <div className="projectlist">
      {project_data.map((data, idx) => (
        <Project data={data} key={idx} />
      ))}
    </div>
  );
}

export default ProjectList;
