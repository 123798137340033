import React from "react";
import "./Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { ExternalLink } from "react-external-link";
import ContactUs from "./MessageMe";
import { motion } from "framer-motion";

function Contact() {
  return (
    <motion.div
      className="contact"
      inital={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <h2>Contact</h2>
      <ContactUs />
      <ul className="contact-icon-list">
        <li>
          <ExternalLink href="https://www.facebook.com/kaung.sithu.100046/">
            <FontAwesomeIcon icon={faFacebook} className="contact-icon" />
          </ExternalLink>
        </li>
        <li>
          <ExternalLink href="https://www.instagram.com/kaungsi43/">
            <FontAwesomeIcon icon={faInstagram} className="contact-icon" />
          </ExternalLink>
        </li>
        <li>
          <ExternalLink href="https://github.com/Kaung722">
            <FontAwesomeIcon icon={faGithub} className="contact-icon" />
          </ExternalLink>
        </li>
        <li>
          <ExternalLink href="https://www.linkedin.com/in/kaung-si-thu-1225251a4/">
            {" "}
            <FontAwesomeIcon icon={faLinkedin} className="contact-icon" />
          </ExternalLink>
        </li>
      </ul>
    </motion.div>
  );
}

export default Contact;
