const data = [
  {
    text: "Burmese-English Over-the-phone interpreter at Language Line Solution",
    date: "December 2020 - Now",
    category: {
      tag: "Interpreter",
      color: "blue",
    },
    link: {
      url: "https://www.languageline.com/",
      text: "Learn more about LanguageLine",
    },
  },
  {
    text: "Worked as a meat clerk at Save-On-Food",
    date: "November 2019 - April 2020",
    category: {
      tag: "Meat Clerk",
      color: "#018f69",
    },
    link: {
      url: "https://www.saveonfoods.com/sm/pickup/rsid/978/",
      text: "Learn more about Save-On-Foods",
    },
  },
];

export default data;
