import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./MessageMe.css";

let ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    if (
      form.current.user_name.value !== "" &&
      form.current.user_email.value !== "" &&
      form.current.message.value !== ""
    ) {
      emailjs
        .sendForm(
          "service_gqwqw2z",
          "template_xnuxkvf",
          form.current,
          "5LoGQoE6H1hAoxW3G"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      form.current.reset();
      alert("Message Sent!");
    } else {
      alert("Incomplete Information!");
    }
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <div className="label_input">
        <label>Name</label>
        <input type="text" name="user_name" />
      </div>
      <div className="label_input">
        <label>Email</label>
        <input type="email" name="user_email" />
      </div>
      <div className="label_input">
        <label>Message</label>
        <textarea name="message" className="message" />
      </div>
      <input className="send-button" type="submit" value="Send" />
    </form>
  );
};

export default ContactUs;
