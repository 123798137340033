import React, { useState } from "react";
import "./Project.css";
import { motion } from "framer-motion";

function Project({ data }) {
  const [isExpanded, setExpended] = useState(false);
  const tempText = data.description;
  let textShown = isExpanded ? tempText : tempText.slice(0, 80) + "...";
  let clickText = isExpanded ? "Read Less" : "Read More";
  const handleCLick = () => {
    setExpended(!isExpanded);
  };
  return (
    <motion.div
      className="project"
      inital={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <div className="project-content">
        <div className="project-title-date">
          {" "}
          <div
            className="project-title"
            style={{ backgroundColor: data.title.color }}
          >
            {data.title.text}
          </div>
          <time>{data.date}</time>
        </div>
        <div className="project-tags">
          {data.tags.map((tag, idx) => (
            <span className="project-tag" key={idx}>
              {tag}
            </span>
          ))}
        </div>
        <p className="project-description">
          {textShown}
          <button
            className="project-description-readmore"
            onClick={handleCLick}
          >
            {" "}
            {clickText}
          </button>
        </p>

        <span className="project-learnmore">
          <a href={data.link.url} target="_blank" rel="noopener noreferrer">
            {data.link.text}{" "}
          </a>
        </span>
      </div>
    </motion.div>
  );
}

export default Project;
