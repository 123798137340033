import React from "react";
import "./About.css";
import Profile from "../images/profile.jpeg";
import { motion } from "framer-motion";

function About() {
  return (
    <motion.div
      className="about"
      inital={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <div className="profile-box">
        <img src={Profile} alt="profile" className="profile-pic" />
        <p className="hi">
          Hi there! My name is Kaung Si Thu, or Jeff. I'm a Computing Science
          student and an aspiring software develper based in British Columbia,
          Canada. I am a fan of good art including movies, anime, cartoons, and
          musics.
        </p>
      </div>
    </motion.div>
  );
}

export default About;
