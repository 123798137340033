import React from "react";
import ProjectList from "./ProjectList";
import "./Projects.css";

function Projects() {
  return (
    <div className="projects">
      <ProjectList />
    </div>
  );
}

export default Projects;
