import React from "react";
import "./CareerTimeLineItem.css";

function CareerTimeLineItem({ data }) {
  return (
    <div className="timeline-item">
      <div className="timeline-item-content">
        <div className="tag_time">
          {" "}
          <span className="tag" style={{ background: data.category.color }}>
            {data.category.tag}
          </span>
          <time className="career-date">{data.date}</time>{" "}
        </div>
        <p className="career-description">{data.text}</p>

        <span className="circle">
          {data.link && (
            <a href={data.link.url} target="_blank" rel="noopener noreferrer">
              {data.link.text}
            </a>
          )}
        </span>
      </div>
    </div>
  );
}

export default CareerTimeLineItem;
