import React, { useState } from "react";
import "./Nav.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

function Nav() {
  const removeNav = () => {
    setExpended(!isExpanded);
  };
  const [isExpanded, setExpended] = useState(false);
  const responsiveNav = isExpanded ? (
    <ul className="nav-list-vertical">
      <Link className="link-style" to="/about" onClick={removeNav}>
        <li>About</li>
      </Link>
      <Link className="link-style" to="/projects" onClick={removeNav}>
        <li>Projects</li>
      </Link>
      <Link className="link-style" to="/career" onClick={removeNav}>
        <li>Career</li>
      </Link>
      <Link className="link-style" to="/contact" onClick={removeNav}>
        <li>Contact</li>
      </Link>
    </ul>
  ) : null;

  const responsiveNavBar = isExpanded ? (
    <FontAwesomeIcon icon={faXmark} />
  ) : (
    <FontAwesomeIcon icon={faBars} />
  );
  return (
    <div className="nav-all">
      <nav className="nav-bar">
        <Link className="link-style-home" to="/">
          <div className="nav-home">Kaung Si Thu (Jeff)</div>
        </Link>
        <div className="nav-toggle" onClick={removeNav}>
          {responsiveNavBar}
        </div>
        <ul className="nav-list-horizontal">
          <Link className="link-style" to="/about">
            <li>About</li>
          </Link>
          <Link className="link-style" to="/projects">
            <li>Projects</li>
          </Link>
          <Link className="link-style" to="/career">
            <li>Career</li>
          </Link>
          <Link className="link-style" to="/contact">
            <li>Contact</li>
          </Link>
        </ul>
      </nav>
      {responsiveNav}
    </div>
  );
}

export default Nav;
