import React from "react";
import "./CareerTimeLine.css";
import timelineData from "./CareerData";
import CareerTimeLineItem from "./CareerTimeLineItem";

function CareerTimeLine() {
  return (
    <div className="timeline-container">
      {timelineData.map((data, idx) => (
        <CareerTimeLineItem data={data} key={idx} />
      ))}
    </div>
  );
}

export default CareerTimeLine;
